// Dependencies
import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Container, Box, Divider, Typography, Button, styled } from '@mui/material'

import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import SaveAltOutlined from '@mui/icons-material/SaveAltOutlined'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'

// Helpers
import { useOrder, FETCH_PROPERTIES } from '@hooks/useOrder'
import useGetYMALItems from '@hooks/useGetYMALItems'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { shouldShowAdditionalInstructions } from '@helpers/delivery'
import { formatPhone } from '@helpers/string-helper'
import { resetOrderAndCart } from '@helpers/checkout/global'

// Components
import { PaymentShortSuccessCards } from '@shared/payment-success-cards-v2'

import useWindow from '@hooks/useWindow'
import FinanceMessage from '@components/checkout/checkout-parts/payment-section/finance-message'
import ProductTileMiniWrapper from '@components/product/ProductTileMini/ProductTileMiniWrapper'
import SuccessHeader from '@components/order/status/order-success/success-header'
import OrderReceiptsSummary from '@components/order/status/order-success/order-receipts-summary'
import BaseSwiper from '../../@rtg2022/components/organisms/BaseSwiper/BaseSwiper'
import Layout from '../../components/layout'

import DeliveryProduct from '../../components/checkout/checkout-parts/delivery-section/delivery-product-v2'
import Delivery from '../../components/order/delivery-v2'
// import SimpleSlider from '../../components/shared/slider'
import PriceAtom from '../../@rtg2022/components/atoms/Price/Price'

// Assets
import AppImage from '../../assets/images/order-success-mobile-app.png'
import AppStore from '../../components/shared/svgs/AppStore'
import GooglePlay from '../../components/shared/svgs/GooglePlay'

const StyledLayout = styled(Layout)(({ theme }) => ({
  backgroundColor: '#F7F7F7 !important',
  '& .content-wrapper': {
    backgroundColor: `${theme.palette.common.white}!important`,
  },
  '@media print': {
    '& > footer, & > *:not(.content-wrapper)': {
      display: 'none !important',
    },
    '& > .content-wrapper > .MuiContainer-root > div:not(#order-receipt)': {
      display: 'none',
    },
    '#order-receipt': {
      marginTop: '0!important',
      '#btn-order-receipt-save': {
        display: 'none',
      },
    },
  },
}))

const OrderContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '30px!important',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}))

const CongratsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '0 15px',
  minHeight: '300px',
  'p:first-of-type': {
    color: theme.palette.success.main,
    fontSize: '25px',
  },
  p: {
    display: 'flex',
    flexDirection: 'column',
  },
  svg: {
    height: '36px',
    width: '36px',
    color: theme.palette.success.main,
    marginRight: '10px',
  },
}))

const ReceiptContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '940px',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    top: 'unset',
  },
}))

const ReceiptHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  color: theme.palette.secondary.dark,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  p: {
    color: theme.palette.primary.dark,
    fontSize: '18px',
  },
  button: {
    svg: {
      marginRight: '8px',
    },
  },
}))

const ReceiptItemsContainer = styled(Box)(({ theme }) => ({
  padding: '15px',
  [theme.breakpoints.down('md')]: {
    padding: '15px 0',
  },
}))

const ProvidedInstructionsContainer = styled(Box)(() => ({
  fontSize: '14px',
  marginTop: '10px',
  span: { fontWeight: 600, marginRight: '5px' },
}))

const DeliveryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '940px',
  padding: '15px',
  [theme.breakpoints.down('md')]: {
    padding: '15px 0',
  },
}))

const TitleHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  color: theme.palette.secondary.dark,
  marginBottom: '8px',
  p: {
    fontSize: '18px',
    fontWeight: 500,
  },
}))

const YMALHeader = styled(Box)(({ theme }) => ({
  margin: '50px 0 20px 0',
  p: {
    fontSize: '25px',
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  [theme.breakpoints.down('md')]: {
    margin: '40px 0 20px 0',
  },
}))

const DeliveryItems = styled(Delivery)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}))

const ShippingAndBillingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxWidth: '940px',
  padding: '15px',
  '.MuiDivider-root': {
    width: '1px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '15px 0',
    flexDirection: 'column',
    '.MuiDivider-root': {
      width: 'auto',
      margin: '15px 0',
    },
  },
}))

const ShippingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  svg: {
    width: '28px',
    height: '28px',
    backgroundColor: '#f5f8fb',
    fill: '#abc6e0',
    borderRadius: '50%',
    padding: '4px',
    marginRight: '8px',
  },
  p: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
  },
  '& > p': {
    margin: '5px 0',
  },
}))

const ShippingData = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  p: {
    textTransform: 'capitalize',
  },
}))

const BillingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  p: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
  },
}))

const VerticalDivider = styled(Divider)(({ theme }) => ({
  margin: '0 30px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const HorizontalDivider = styled(Divider)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

const SectionDivider = styled(Divider)(({ theme }) => ({
  margin: '10px 0',
  maxWidth: '940px',
  borderColor: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    margin: 0,
  },
}))

const OrderSuccess = ({ '*': orderId }) => {
  const {
    orderData,
    contact,
    shippingAddress,
    cartItems,
    lineItems,
    subtotal,
    total,
    amountDue,
    totalSavings,
    totalDeliveryCharge,
    tax,
    splitDeliveryDates,
    isPickup,
    isExpress,
    defaultDelivery,
    doorwayDelivery,
    doorwayDeliveryCharge,
    additionalDirections,
    aggCartItems,
    aggOrderLineItems,
    aggDeliveryItems,
    aggUpsDeliveryItems,
    aggUspsDeliveryItems,
    aggVendorDeliveryItems,
    splitCalendar,
    accountExists,
  } = useOrder({ orderId }, [FETCH_PROPERTIES.FETCH_ORDER, FETCH_PROPERTIES.FETCH_PRODUCTS_FROM_CART])

  const { windowContext } = useWindow()

  const ymalSkus = useMemo(() => {
    if (lineItems?.length) {
      return lineItems
        .map(item => {
          if (item?.isConfigurable) {
            const childSkus = [...new Set(item?.childItems.map(i => i.sku))].join(',') ?? ''
            return childSkus
          }
          return item?.sku ?? ''
        })
        .filter(x => x)
        .join(',')
    }
    if (cartItems?.length) {
      return cartItems
        .filter(i => !i?.isConfigurable)
        .map(item => item?.sku ?? item?.product?.sku ?? '')
        .filter(x => x)
        .join(',')
    }
    return ''
  }, [cartItems, lineItems])

  const { data: carouselSlidesData } = useGetYMALItems({}, ymalSkus)

  const handleReceiptSave = () => {
    windowContext?.print()
  }

  useEffect(() => {
    resetOrderAndCart()
    setupAnalytics({ pageData: { type: 'order', title: 'Order Success', path: '/order-success' } })
  }, [])

  const trustPilotCSS = `
    #abt-test-modal {
      z-index: 2000 !important;
    }
    .abt-close-icon {
      left: 0px !important;
    }`

  const slides = useMemo(
    () =>
      carouselSlidesData?.map((product, index) => (
        <ProductTileMiniWrapper sku={product?.sku} index={index} key={product?.sku} source="you may also like" />
      )),
    [carouselSlidesData],
  )

  return (
    <StyledLayout>
      <Helmet title="Order Successful - Rooms To Go" />
      {contact ? (
        <OrderContainer className="order-success">
          <style>{trustPilotCSS}</style>
          <iframe title="ifm" id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }} />
          <SuccessHeader contact={contact} shippingAddress={shippingAddress} showAccountForm={!accountExists} />

          <ReceiptContainer id="order-receipt">
            <ReceiptHeader>
              <Typography>ORDER RECEIPT</Typography>
              <Button id="btn-order-receipt-save" onClick={handleReceiptSave}>
                <SaveAltOutlined />
                Save
              </Button>
            </ReceiptHeader>
            <ReceiptItemsContainer>
              {aggCartItems &&
                aggCartItems.length > 0 &&
                aggCartItems.map(item => (
                  <DeliveryProduct
                    key={item?.product?.sku ?? item?.sku}
                    item={{
                      ...item,
                      lineItem: lineItems?.find(lineItem => lineItem?.sku === item?.sku) ?? item?.lineItem,
                    }}
                    warrantyEnabled={item.warrantyEnabled}
                  />
                ))}
            </ReceiptItemsContainer>

            <Divider />
            <OrderReceiptsSummary
              amountDue={amountDue}
              paymentInfo={orderData?.paymentInfo}
              subtotal={subtotal}
              tax={tax}
              total={total}
              totalDeliveryCharge={totalDeliveryCharge}
              totalSavings={totalSavings}
            />
          </ReceiptContainer>

          <SectionDivider />

          <DeliveryContainer>
            {orderData && (
              <>
                <DeliveryItems
                  order={{
                    ...orderData,
                    isPickup,
                    isExpress,
                    defaultDelivery,
                    doorwayDelivery,
                    doorwayDeliveryCharge,
                    totalDeliveryCharge,
                    splitDeliveryDates,
                    aggCartItems,
                    aggOrderLineItems,
                    aggDeliveryItems,
                    aggUpsDeliveryItems,
                    aggUspsDeliveryItems,
                    aggVendorDeliveryItems,
                    splitCalendar,
                  }}
                />

                {!splitDeliveryDates && additionalDirections && shouldShowAdditionalInstructions(orderData) && (
                  <ProvidedInstructionsContainer className="provided-instructions-wrapper">
                    <span>Provided Instructions:</span>
                    {additionalDirections}
                  </ProvidedInstructionsContainer>
                )}
              </>
            )}
          </DeliveryContainer>

          <SectionDivider />

          <ShippingAndBillingContainer>
            <ShippingContainer>
              <TitleHeader>
                <Typography>Shipping Address</Typography>
              </TitleHeader>
              <Typography>
                <PersonIcon />
                <ShippingData>
                  <Typography>{`${contact?.firstName} ${contact?.lastName}`}</Typography>
                  <Typography>{`${shippingAddress?.address1} ${shippingAddress?.address2}`}</Typography>
                  <Typography>{`${shippingAddress?.city}, ${shippingAddress?.state?.toUpperCase()} ${
                    shippingAddress?.zip
                  }`}</Typography>
                </ShippingData>
              </Typography>
              <Typography>
                <EmailIcon />
                <Typography>{contact?.email}</Typography>
              </Typography>
              <Typography>
                <PhoneIcon />
                <Typography>{formatPhone(contact?.phone)}</Typography>
              </Typography>
            </ShippingContainer>
            <VerticalDivider orientation="vertical" flexItem />
            <HorizontalDivider orientation="horizontal" flexItem />
            <BillingContainer>
              <TitleHeader>
                <Typography>Billing and Payments</Typography>
              </TitleHeader>
              <PaymentShortSuccessCards order={orderData} />
              <FinanceMessage paymentInfo={orderData.paymentInfo} isSuccess />
            </BillingContainer>
          </ShippingAndBillingContainer>

          {slides?.length > 0 && (
            <>
              <YMALHeader>
                <Typography>You May Also Like</Typography>
              </YMALHeader>
              <BaseSwiper
                title="You May Also Like"
                slides={slides}
                slidesPerViewDesktop="auto"
                slidesPerViewMobile="auto"
                spaceBetween={15}
                fadeScrollbar
                freeModeDesktop
                freeModeMobile
                dataTestId="slider:you-may-also-like"
                cssMode
              />
            </>
          )}
        </OrderContainer>
      ) : (
        /* If order object is NULL still display a success message.
            This happens when an store cart item is not available online.
        */
        <OrderContainer className="order-success">
          <CongratsContainer>
            <CheckCircleOutlined />
            <Typography>
              <Typography>CONGRATULATIONS!</Typography>
              <Typography>Your order is being processed.</Typography>
            </Typography>
          </CongratsContainer>
        </OrderContainer>
      )}
    </StyledLayout>
  )
}

OrderSuccess.propTypes = {
  '*': PropTypes.any,
}

export default OrderSuccess
