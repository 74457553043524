import React from 'react'
import { array, bool } from 'prop-types'
import { Typography } from '@mui/material'
import { isMonthsAboveThreshold } from './finance-plan/helpers'

const FinanceMessage = ({ paymentInfo, isSuccess }) => {
  if (paymentInfo.some(p => p.paymentType === 'DBUY' && isMonthsAboveThreshold(p?.paymentProperties?.financePlan))) {
    return (
      <Typography sx={{ paddingTop: '16px', fontSize: '14px !important' }}>
        {isSuccess
          ? `You have made this purchase using the Rooms To Go credit card. A promo fee of 2% of the amount financed will be charged by 
          Synchrony and included in required monthly payments. This additional 2% fee is not reflected on your Rooms To Go receipt 
          but will be shown as a separate transaction on your monthly statements from Synchrony.`
          : `Synchrony will charge an additional fee of 2% of the financed amount.`}
      </Typography>
    )
  }
  return null
}

FinanceMessage.propTypes = {
  paymentInfo: array,
  isSuccess: bool,
}

export default FinanceMessage
