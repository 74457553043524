import React from 'react'
import { object, string } from 'prop-types'
import { Typography, Box, styled } from '@mui/material'
import '../../assets/css/components/order/delivery.sass'
import { splitDates } from '@helpers/checkout/delivery-section'
import { renderDoorwayDelivery, getDeliveryLabel } from '@helpers/delivery'
import { getDateFull } from '@helpers/date'
import RTGLink from '@shared/link'
import SplitDeliverySummary from '@components/shared/split-delivery-summary-v2'
import DeliveryProduct from '../checkout/checkout-parts/delivery-section/delivery-product-v2'
import DistributionCenterPopup from '../checkout/checkout-parts/delivery-section/distribution-center-popup'
import DeliveryTile from '../../@rtg2022/components/molecules/DeliveryTile/delivery-tile'

const Summary = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  width: '100%',
})
const DeliveryContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '& > div > div > div': {
      margin: '15px 0',
    },
    h4: {
      margin: 0,
    },
  },
}))

// const Summary = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   textAlign: 'left',
//   maxWidth: '45%',
//   [theme.breakpoints.down('md')]: {
//     maxWidth: '100%',
//   },
// }))

const SummaryGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  textAlign: 'left',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
}))

const StyledList = styled('ul')(({ theme }) => ({
  listStyle: 'inside',
  maxWidth: '45%',
  fontSize: '14px',
  'li:last-of-type': {
    marginBottom: '10px',
  },
  a: {
    color: theme.palette.primary.main,
    fontSize: '14px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}))

const DeliveryProductCart = styled(DeliveryProduct)(() => ({
  padding: '15px 0',
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(0, 83, 160, 0.12)`,
  },
}))

const DeliveryTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500,
})

export const generateAutomatedMessage = (calendarType, isDoorwayDelivery, isExpressDelivery) => {
  if (isDoorwayDelivery) {
    return (
      <StyledList>
        <li>{`You will be notified of the estimated delivery window ${
          isExpressDelivery ? '' : '2 days '
        }prior to delivery.`}</li>
        <li>Signature required upon delivery.</li>
        <li>Does NOT include professional assembly or removal of packaging.</li>
        By selecting Doorway Delivery, you agree that you are solely responsible for assembly as set forth in the&nbsp;
        <RTGLink
          action="click"
          style={{ lineHeight: '0.7rem' }}
          data={{
            url: 'https://misc.rtg-prod.com/rtgcom-terms-of-sale.pdf',
            title: 'Rooms To Go Terms of Use',
            action: 'click',
            target: '_blank',
          }}
        >
          Online Terms and Conditions of Sale, Limited Product Warranty and Dispute Resolution/Arbitration Agreement
        </RTGLink>
        .
      </StyledList>
    )
  }
  if (calendarType && calendarType === 'weekly') {
    return 'You will receive a call on the Thursday prior to the date that you selected. This call will identify the specific date and time that your merchandise will be delivered, within 12 days from the Monday you choose for your departure date.'
  }
  return `You will be notified of the estimated delivery window ${isExpressDelivery ? '' : '2 days '}prior to delivery.`
}

const OrderDelivery = ({ order, className }) => {
  const {
    calendarType,
    defaultDelivery,
    doorwayDelivery,
    isPickup,
    otherDeliveryItems = [],
    aggDeliveryItems = [],
    splitDeliveryDates = null,
    aggUpsDeliveryItems,
    aggUspsDeliveryItems,
    aggVendorDeliveryItems,
  } = order || {}

  const allProduct = [...aggDeliveryItems, ...otherDeliveryItems]

  const deliveryDate = getDateFull(order.deliveryDate)
  const estimatedDeliveryRange = defaultDelivery ? splitDates(order.deliveryDate).splitDatesRange : null
  const hasDeliveryItems =
    aggDeliveryItems.length > 0 ||
    aggUpsDeliveryItems.length > 0 ||
    aggUspsDeliveryItems.length > 0 ||
    aggVendorDeliveryItems.length > 0

  return (
    <DeliveryContainer className={className}>
      {aggDeliveryItems?.length > 0 && splitDeliveryDates && <SplitDeliverySummary order={order} />}
      {/* PICKUP ITEMS */}
      {isPickup && (
        <>
          <DeliveryTitle>Pickup</DeliveryTitle>
          {!splitDeliveryDates && (
            <>
              <Summary>
                <SummaryGroup>
                  <DeliveryTile
                    subheaderComponent={() => (
                      <Typography>
                        Pick-up from <DistributionCenterPopup order={order} /> on: {deliveryDate}
                      </Typography>
                    )}
                  >
                    {allProduct.map(item => (
                      <DeliveryProductCart
                        key={item.sku}
                        item={item}
                        warrantyEnabled={item.warrantyEnabled}
                        displayPrice={false}
                      />
                    ))}
                  </DeliveryTile>
                </SummaryGroup>
              </Summary>
            </>
          )}
        </>
      )}
      {/* DELIVERY ITEMS */}
      {hasDeliveryItems && <DeliveryTitle>Delivery</DeliveryTitle>}
      {!isPickup && aggDeliveryItems?.length > 0 && !splitDeliveryDates && (
        <Summary>
          <SummaryGroup>
            <DeliveryTile
              title={defaultDelivery ? estimatedDeliveryRange : deliveryDate}
              subheaderComponent={() => renderDoorwayDelivery(order, doorwayDelivery)}
            >
              {aggDeliveryItems.map(item => (
                <DeliveryProductCart
                  key={item.sku}
                  item={item}
                  warrantyEnabled={item.warrantyEnabled}
                  displayPrice={false}
                />
              ))}
            </DeliveryTile>
          </SummaryGroup>
          <Box>{generateAutomatedMessage(calendarType, doorwayDelivery, order.isExpress)}</Box>
        </Summary>
      )}
      {aggVendorDeliveryItems.length > 0 && (
        <Summary>
          <SummaryGroup>
            <DeliveryTile
              title={getDeliveryLabel(aggVendorDeliveryItems?.[0]?.deliveryType, doorwayDelivery)}
              subheaderComponent={() => renderDoorwayDelivery(order, true, true)}
            >
              {aggVendorDeliveryItems.map(item => (
                <DeliveryProductCart
                  key={item.sku}
                  item={item}
                  warrantyEnabled={item.warrantyEnabled}
                  displayPrice={false}
                />
              ))}
            </DeliveryTile>
          </SummaryGroup>
        </Summary>
      )}
      {!isPickup && aggUpsDeliveryItems.length > 0 && (
        <Summary>
          <SummaryGroup>
            <DeliveryTile
              title={getDeliveryLabel(aggUpsDeliveryItems?.[0]?.deliveryType, doorwayDelivery)}
              subheaderComponent={() => renderDoorwayDelivery(order, true, true)}
            >
              {aggUpsDeliveryItems.map(item => (
                <DeliveryProductCart
                  key={item.sku}
                  item={item}
                  warrantyEnabled={item.warrantyEnabled}
                  displayPrice={false}
                />
              ))}
            </DeliveryTile>
          </SummaryGroup>
        </Summary>
      )}
      {!isPickup && aggUspsDeliveryItems.length > 0 && (
        <Summary>
          <SummaryGroup>
            <DeliveryTile
              title={getDeliveryLabel(aggUspsDeliveryItems?.[0]?.deliveryType, doorwayDelivery)}
              subheaderComponent={() => renderDoorwayDelivery(order, true, true)}
            >
              {aggUspsDeliveryItems.map(item => (
                <DeliveryProductCart
                  key={item.sku}
                  item={item}
                  warrantyEnabled={item.warrantyEnabled}
                  displayPrice={false}
                />
              ))}
            </DeliveryTile>
          </SummaryGroup>
        </Summary>
      )}
    </DeliveryContainer>
  )
}

OrderDelivery.propTypes = {
  order: object,
  className: string,
}

export default OrderDelivery
